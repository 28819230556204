/* EnterpriceProductComponent.css */

.manufactiring-component-div {
    background-color: #333;
}

.manufactiring-card-wrapper {
    max-width: 1100px;
    margin: 0 auto;
}

.manufactiring-img-display {
    overflow: hidden;
}

.manufactiring-img-showcase {
    display: flex;
    width: 100%;
    transition: all 0.5s ease;
}

.manufactiring-img-showcase .manufactiring-img-product {
    min-width: 100%;
}

.manufactiring-img-select {
    display: flex;
}

.manufactiring-img-item {
    margin: 0.3rem;
}

.manufactiring-borderbackground {
    background: none;
    border: none;
}

.manufactiring-img-item:nth-child(1),
.manufactiring-img-item:nth-child(2),
.manufactiring-img-item:nth-child(3) {
    margin-right: 0;
}

.manufactiring-img-item:hover {
    opacity: 0.8;
}

.manufactiring-product-content {
    padding: 2rem 1rem;
}

.manufactiring-product-title {
    font-size: 3rem;
    text-transform: capitalize;
    font-weight: 700;
    position: relative;
    color: #bfd5eb;
    margin: 1rem 0;
}

.manufactiring-about-info {
    color: white;
}

.manufactiring-feature {
    color: white;
}

.manufactiring-component-button-container {
    display: flex;
    justify-content: center;
    height: 150px;
    min-height: 150px;
    align-items: center;
}

.component-button-download {
    background-color: white;
    color: #1a72bc;
    border: none;
    padding: 10px 40px;
    margin: 0 20px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    border-radius: 20px;
}

.component-button-download:hover {
    background-color: black;
    color: white;
    border: 1px solid blue;
}

.hyperlink-button-manufactiring {
    background-color: #333;
    color: #0056b3;
    border: none;
    cursor: pointer;
    font-size: 16px;
}

.hyperlink-button-manufactiring:hover {
    color: white;
    text-decoration: underline;
}

.casestudy-button-manufactiring {
    margin-top: 50px;
    margin-left: 20px;
}

.component-button-enquiry {
    background-color: white;
    color: #1a72bc;
    border: none;
    padding: 10px 40px;
    margin: 0 20px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    border-radius: 20px;
}

.component-button-enquiry:hover {
    background-color: black;
    color: white;
    border: 1px solid blue;
}

@media only screen and (max-width: 600px) {
    .manufactiring-card-wrapper {
        padding-top: 80px;
    }

    .manufactiring-component-button-container {
        margin-top: 30px;
        height: 50px;
        min-height: 50px;
    }
}

@media only screen and (min-width: 600px) {
    .manufactiring-card-wrapper {
        padding-top: 150px;
    }
}

@media only screen and (min-width: 768px) {
    .manufactiring-card-wrapper {
        padding-top: 150px;
    }

    .manufactiring-component-button-container {
        height: 100px;
        min-height: 100px;
    }
}

@media screen and (min-width: 992px) {

    .manufactiring-card-product {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 1.5rem;
    }

    .manufactiring-card-wrapper {
        height: 100vh;
        padding-top: 0;
        margin-top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .manufactiring-product-imgs {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .manufactiring-product-content {
        padding-top: 0;
    }
}