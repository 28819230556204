.DividingIcons-btn {
    display: flex;
    width: 200px;
    align-items: center;
    justify-content: space-between;
    padding-left: 14px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    border: 1px;
    border-bottom-right-radius: 30px;
    border-top-right-radius: 30px;
    margin-bottom: 5px;
    position: relative;
    left: -145px;
    transition: left 1s;
    border: 2px solid #00ffff;
    height: 68px;
}

.DividingIcons-btn:hover {
    left: -10px;
    transition: left 1s;
}

.DividingIcons {
    position: fixed;
    top: 200px;
    z-index: 1;
}

.DividingIcons a {
    text-decoration: none;
}

.color-telegram {
    background-color: #353333;
}

.google-font {
    font-size: -3.75rem;
}

.DividingIcons-btn img {
    width: 40px;
}

.DividingIcons-btn p {
    color: white;

    margin-top: 0px;
    margin-bottom: 0px;
}

.icons8-telegram-app {
    order: 2;
    display: inline-block;
    width: 40px;
    height: 40px;
    background-size: 100%;
}

.icons8-instagram {
    order: 2;
    display: inline-block;
    width: 40px;
    height: 40px;
    background-size: 100%;
}

.icons8-whatsapp {
    order: 2;
    display: inline-block;
    width: 38px;
    height: 38px;
    background-size: 100%;
}

.DividingIcons-btn img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

@media (max-width: 768px) {
    .DividingIcons-btn img {
        width: 20px;
        height: 20px;
        border-radius: 50%;
    }

    .DividingIcons-btn {

        width: 180px;
        height: 30px;
    }

    .google-font {
        font-size: 10px;
    }

    .DividingIcons {
        top: 100px;
    }
}