/* EnterpriseDataCenterConsultingSubServicePage.css */
/* EnterpriseDataCenterConsultingSubServicePage.css */

.enterprise-servive-des {
    background-color: #1a1a1a;
    border-radius: 8px;
    padding: 20px;
    margin: 20px 30px;
    color: white;
    line-height: 1.6;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    text-align: center;
  }
  
  .enterprise-servive-des:hover {
    transform: scale(1.02);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  }
  
  .enterprise-service-page {
    background-color: black;
  }
  
  .enterprise-subservice-header-datacenter {
    padding-top: 100px;
    width: 100%;
    height: 95vh;
    background: linear-gradient(rgba(0, 0, 0, 0.55), rgba(0, 0, 0, 0.55)),
      url('../../../assets/images/data-center.jpg');
    background-size: cover;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .enterprise-subheader-text {
    width: 700px;
    text-align: center;
  }
  
  .enterprise-subservice-header-datacenter .enterprise-subservice-header-heading {
    margin-bottom: 10px;
    font-weight: 500;
    font-size: 45px;
    color: white;
  }
  
  #enterprise-subservice-header-subheading {
    font-size: 60px;
    letter-spacing: 0.5px;
  }
  
  .enterprise-subservice-header-datacenter .enterprise-subservice-header-description {
    margin-bottom: 20px;
    color: white;
    font-size: 18px;
  }
  
  .enterprise-subservice-header-datacenter .enterprise-subservice-header-button {
    border: 2px solid white;
    color: white;
    padding: 10px;
    font-size: 18px;
    width: 160px;
    border-radius: 500px;
    cursor: pointer;
    background-color: transparent;
    transition: all 0.2s ease-in-out;
  }
  
  .enterprise-subservice-header-datacenterr .enterprise-subservice-header-button:hover,
  .enterprise-subservice-header-button:active {
    background-color: white;
    color: black;
  }
  
  .Downloadpdf {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 12px 24px;
    font-size: 16px;
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
  }
  
  .Downloadpdf:hover {
    background-color: #0056b3;
    transform: translateY(-2px);
  }
  
  .Downloadpdf:active {
    background-color: #004085;
    transform: translateY(0);
  }
  
  .Downloadpdf:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5);
  }
  .servicesenquiry{
    background-color: white;
    color: #007bff;
    border: 2px solid #007bff;
    padding: 12px 24px;
    font-size: 16px;
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s, transform 0.3s;

  }
  

  .EnquiryButton {
    background-color: white;
    color: #007bff;
    border: 2px solid #007bff;
    padding: 12px 24px;
    font-size: 16px;
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s, transform 0.3s;
  }
  
  .servicesenquiry:hover {
    background-color: #007bff;
    color: white;
    transform: translateY(-2px);
  }
  
  .servicesenquiry:active {
    background-color: #0056b3;
    transform: translateY(0);
  }
  
  .servicesenquiry:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5);
  }
  
  .button-container {
    display: flex;
    justify-content: center;
    gap: 20px;
  }
  
  .enterprise-subservice-card-div-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
    padding: 20px;
  }
  
  .enterprise-subservice-card-div {
    background: #262626;
    color: white;
    width: 300px;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  }
  
  .enterprise-subservice-card-div:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  }
  
  .enterprise-subservice-card-img-box {
    width: 100%;
    height: 150px;
    overflow: hidden;
    border-radius: 8px;
    margin-bottom: 15px;
  }
  
  .enterprise-subservice-card-img-box img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .enterprise-subservice-card-div-content {
    text-align: center;
  }
  
  .enterprise-subservice-card-div-heading {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .enterprise-subservice-card-div-description {
    font-size: 16px;
    line-height: 1.5;
  }
  
  /* Add your custom styles here */
  

@media (max-width: 480px) {
    .enterprise-subservice-card-div-container .enterprise-subservice-card-div {
        width: 230px;
        border-radius: 15px;
    }

    .enterprise-subservice-card-div-container .enterprise-subservice-card-div .enterprise-subservice-card-img-box img {
        border-radius: 10px;
    }

    .enterprise-subservice-card-div-container .enterprise-subservice-card-div .enterprise-subservice-card-div-content .enterprise-subservice-card-div-description {
        font-size: 0.8rem;
    }

    .enterprise-subservice-card-div-container .enterprise-subservice-card-div .enterprise-subservice-card-div-content a {
        font-size: 0.9rem;
    }
}