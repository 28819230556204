/* SwitchtoEnterprise.css */

.switch-button {
    position: fixed;
    z-index: 10;
    left: -100px;
    top: 50%;
    transform: translateY(-50%);
    background-color: #007bff;
    color: #fff;
    padding: 5px 10px;
    border-radius: 3px;
    cursor: pointer;
    transition: left 0.5s ease;
    width: 150px;
    white-space: normal;
}

.switch-button.slide-in {
    left: 10px;
}

.switch-button.slide-out {
    left: -150px;
}

.tooltip {
    position: absolute;
    top: -40px;
    left: 50%;
    transform: translateX(-50%);
    padding: 10px;
    background-color: #333;
    color: #fff;
    border-radius: 5px;
    font-size: 14px;
    opacity: 0.8;
    display: none;
}

.switch-button:hover .tooltip {
    display: block;
}