.EnterpriseHero {
    position: absolute;
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    width: 100%;
    padding: 0 20px;
    text-align: center;
    margin-top: 60vh;
}

.EnterpriseHero-text {
    color: #fff;
    font-size: min(10vw, 110px);
    font-weight: 500;
    line-height: 1.2;
}

.EnterpriseHero-explore {
    display: flex;
    align-items: center;
    gap: 5vw;
    margin-top: 5vh;
    padding: 5px 8px;
    border-radius: 60px;
    background-color: #fff;
    cursor: pointer;
}

.EnterpriseHero-explore p {
    color: #292929;
    font-size: min(4vw, 20px);
    font-weight: 500;
}

.EnterpriseHero-dot-play {
    margin-top: 15vh;
    width: 80%;
    max-width: 800px;
    display: flex;
    justify-content: space-between;
}

.EnterpriseHero-dots {
    display: flex;
    align-items: center;
    gap: 2vw;
    list-style: none;
}

.hero-dot {
    width: 1vw;
    height: 1vw;
    background: #fff;
    border-radius: 50%;
    cursor: pointer;
}

.hero-dot.blue {
    background: #00A6FF;
}

.EnterpriseHero-play {
    display: flex;
    align-items: center;
    gap: 2vw;
}

.EnterpriseHero-play p {
    color: #fff;
    font-size: min(1.5vw, 19px);
}

@media (max-width: 768px) {

    .EnterpriseHero-play p {
        color: #fff;
        font-size: min(5.5vw, 25px);
    }

    .EnterpriseHero-play>img {
        height: 30px;
        width: 30px;
    }

    .EnterpriseHero {
        margin-top: 33vh;
    }
}