@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: 0;
  text-decoration: none;
  font-family: "PT Sans", sans-serif !important;
  font-weight: 400;
  font-style: normal;
}

.main-heading-entire-site {
  font-size: 30px !important;
  font-weight: 900 !important;
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 768px) {
  .main-heading-entire-site {
    font-size: 25px !important;
  }
}

/* MainContent.css */
.main-content {
  background-image: url('./assets/images/logo.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100vh;
  width: 100%;
  background-size: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}