#features {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 40px;
    background-color: #343434;
}

#features h1 {
    color: #fff;
    font-size: 3rem;
}

.a-container {
    display: flex;
    justify-content: center;
    align-content: center;
    width: 100%;
}

.a-box {
    background-image: linear-gradient(to bottom right, #252525, #4a4a4a);
    width: 250px;
    height: 350px;
    margin: 20px;
    border-radius: 10px;
    overflow: hidden;
    transition: 0.4s ease-in-out;
    position: relative;
}

.a-b-img {
    width: 100%;
    height: 60%;
    display: flex;
    justify-content: center;
}

.a-b-img img {
    padding: 15px;
    margin-top: 40px;
    width: 100%;
    height: 50%;
    padding: 10px;
    background-color: #ffffff2e;
}

.a-box:hover {
    border: 1px solid #00c9ff;
    box-shadow: 2px 2px 12px rgba(0, 98, 184, 0.445);

    /* scale animation for cards */
    transform: scale(1.05);

    /* scale animation for cards */
   

    /* tilt animation for cards */
    /* transform: rotate(5deg); */

    /* bounce animation for cards */
    /* animation: bounce 0.6s; */
}

/* @keyframes bounce {

    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
    }

    40% {
        transform: translateY(-20px);
    }

    60% {
        transform: translateY(-10px);
    }
} */

.a-b-text {
    width: 100%;
    background-color: #ffffff00;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
}

.a-b-text h2 {
    color: #fff;
    padding: 10px;
}

.a-b-text p {
    margin: 0px;
    color: #ffffffa6;
    font-size: 1.1rem;
    display: block;
    display: -webkit-box;
    max-width: 80%;
    margin-top: 5px;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 10px;
}

.a-b-text p:hover {
    color: #ffffff;
}

.a-b-text a {
    margin-top: 15px;
}

@media(max-width: 970px) {
    #features {
        height: auto;
    }

    .a-container {
        flex-wrap: wrap;
    }

    .a-box {
        flex-grow: 1;
    }

    .a-b-img img {
        object-fit: contain;
    }
}

@media (max-width: 768px) {
    .a-box {
        width: 200px !important;
        height: 260px !important;
        border: 1px solid #00c9ff;
        box-shadow: 2px 2px 12px rgba(0, 98, 184, 0.445);
    }

    .a-b-text {
        height: 10%;
    }

    .a-b-text h2 {

        padding: 10px;
    }

    .a-b-img {

        width: 40%;
        height: 70%;
    }

}