.case-study-div {
    padding: 40px;
    background-color: #282828;
    background-color: #3b3b3b;
}

.case-study {
    max-width: 800px;
    margin: 0 auto;
    padding-top: 30px;
}

.case-study-title {
    color: #007bff;
    text-align: center;
}

.case-study-table {
    margin-top: 20px;
    overflow-x: auto;
}

table {
    width: 100%;
    border-collapse: collapse;
    border: 2px solid #007bff;
    border-radius: 10px;
    color: white;
    /* Changed text color to white */
    transition: all 0.3s ease;
    /* Added transition for entire table */
}

td {
    padding: 10px;
    border-bottom: 1px solid #007bff;
}

.table-label {
    font-weight: bold;
}

table td:first-child {
    border-right: 1px solid #007bff;
}

.read-more-btn {
    display: block;
    margin: 20px auto;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 16px;
}

.read-more-btn:hover {
    background-color: #0056b3;
}

.popup {
    flex-direction: column;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    width: 80%;
    max-width: 400px;
}

.close-btn {
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 16px;
    color: #555;
    transition: color 0.3s ease;
}

.close-btn:hover {
    color: #333;
}

.popup-message {
    margin-bottom: 15px;
    margin-top: 15px;
}

.email-form {
    display: flex;
    flex-direction: column;
    width: 20vw;
}

.email-form input {
    flex: 1;
    padding: 10px;
    margin-bottom: 10px;
}

.email-form button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.email-form button:hover {
    background-color: #0056b3;
}




/* EnterpriseCaseStudy.css */

.download-button-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.download-btn {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.download-btn:hover {
    background-color: #0056b3;
}


/* media queries */

@media (max-width: 480px) {
    .popup {
        width: 90%;
        padding: 15px;
    }

    .case-study {
        min-height: 685px;
    }

    .email-form {
        width: 40vw;
    }
}


/* Hero */

.Background-fixed {
    background-attachment: fixed;
    background-position: center;
}

.ImageBackgroundWrap {
    position: relative;
    z-index: 0;
    background-size: cover;
    background-repeat: no-repeat;
}

.ImageInnerWrap {
    max-width: 900px;
    margin: 0 auto;
    padding-top: 200px;
    padding-bottom: 100px;
}

#CaseStudyPageInnerDiv {
    padding-top: 400px !important;
}

.TextBackground {
    background-color: #b9b2b2e3;
    padding: 5%;
    color: #007bff;
    text-align: center;
}