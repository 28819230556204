.icon-container {
    position: fixed;
    bottom: 20px;
    left: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.plus-icon {
    width: 60px;
    height: 60px;
    background-color: #007bff;
    /* Blue background */
    color: white;
    font-size: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.3s ease;
}

.icons {
    display: flex;
    position: absolute;
    top: 0;
    /* Initially at the plus icon */
    opacity: 0;
    pointer-events: none;
    transition: all 0.3s ease;
    transform: scale(0);
}

.icons.show {
    opacity: 1;
    pointer-events: auto;
    transform: scale(1);
}

/* General styling for icons */
.icons a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45px;
    height: 45px;
    margin: 10px;
    background-color: white;
    /* Default background for Mail icon */
    color: black;
    border-radius: 50%;
    text-decoration: none;
    transition: all 0.3s ease;
    position: absolute;
}

/* WhatsApp Icon - Green */
.whatsapp-icon {
    background-color: #25D366 !important;
    /* WhatsApp Green */
    transform: translate(100px, -100px);
    /* Initially position off-screen */
    transition: transform 0.3s ease;
}

/* Mail Icon - White */
.mail-icon {
    background-color: white;
    /* Blue border for contrast */
    transform: translate(-100px, 100px);
    /* Initially position off-screen */
    transition: transform 0.3s ease;
}

.icons a:hover {
    transform: scale(1.2);
    /* Slight hover effect */
}

/* Slide-out and slide-in animation */
.icons.show .whatsapp-icon {
    transform: translate(-48px, -73px);
}

.icons.show .mail-icon {
    transform: translate(4px, -74px);
}

.icons.show .linkedin-icon {
    transform: translate(38px, -40px);
}

.icons.show .insta-icon {
    transform: translate(29px, 14px);
}



.icon-border {
    border: 2px solid #007bff;
}

.fab,
.fas {
    font-size: 22px;
}

/* Keyframes for rotation */
@keyframes rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

/* Add rotation class */
.plus-icon.rotate {
    animation: rotate 0.5s linear;
}