.header-logo-withoutbg {
    height: 80px;
}

.custom-header-unique {
    position: fixed;
    z-index: 10;
    left: 0;
    right: 0;
    top: 40px;
    height: 100px;
    background-color: rgba(0, 0, 0, 0.6);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 0 5%;
    transition: all 0.3s ease;
}

.custom-header-unique.scrolled {
    height: 100px;
    background-color: #373737;
    border: 2px solid transparent;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-color: #00c9ff;
}

@keyframes backgroundAnimation {
    0% {
        background-color: #0e0c0c;
    }

    50% {
        background-color: #0a0a0a;
    }

    100% {
        background-color: #0e0c0c;
    }
}


.custom-header-unique .logo-unique {
    float: left;
    width: 29%;
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 24px;
    color: #fff;
}

.custom-header-unique .links-unique {
    float: right;
    padding: 0;
    margin: 0;
    height: 100%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    align-items: center;
}

.custom-header-unique .links-unique li {
    list-style: none;
}

.custom-header-unique .links-unique a {
    display: block;
    font-size: 22px;
    font-weight: bold;
    color: #fff;
    position: relative;
    transition: color 0.3s ease, background-color 0.3s ease;
    padding: 10px 15px;
}

.custom-header-unique .links-unique .a-link:hover {
    color: #00c9ff;
    background-color: rgba(255, 255, 255, 0.1);
    height: 70px;
    align-items: center;
    display: flex;
}

.active {
    color: #00c9ff;
    border: 2px solid #00c9ff;
    border-radius: 6px;
}


#nav-toggle-unique {
    position: absolute;
    top: -100px;
}

.custom-header-unique .icon-burger-unique {
    display: none;
    position: absolute;
    right: 5%;
    top: 50%;
    transform: translateY(-50%);
}

.custom-header-unique .icon-burger-unique .line-unique {
    width: 30px;
    height: 5px;
    background-color: #fff;
    margin: 5px;
    border-radius: 3px;
    transition: all 0.5s ease-in-out;
}

@media screen and (max-width: 1400px) {
    .custom-header-unique .logo-unique {
        float: none;
        width: auto;
        justify-content: flex-start;
    }

    .custom-header-unique .links-unique {
        float: none;
        position: fixed;
        z-index: 9;
        left: 0;
        right: 0;
        top: 140px;
        bottom: 100%;
        width: auto;
        height: auto;
        flex-direction: column;
        justify-content: space-evenly;
        background-color: rgba(0, 0, 0, .8);
        overflow: hidden;
        transition: all 0.5s ease-in-out;
    }

    .custom-header-unique .links-unique a {
        font-size: 20px;
    }

    #nav-toggle-unique:checked~.links-unique {
        bottom: 0;
    }

    .custom-header-unique .icon-burger-unique {
        display: block;
    }

    #nav-toggle-unique:checked~.icon-burger-unique .line-unique:nth-child(1) {
        transform: translateY(10px) rotate(225deg);
    }

    #nav-toggle-unique:checked~.icon-burger-unique .line-unique:nth-child(3) {
        transform: translateY(-10px) rotate(-225deg);
    }

    #nav-toggle-unique:checked~.icon-burger-unique .line-unique:nth-child(2) {
        opacity: 0;
    }
}

@media screen and (max-width: 768px) {

    .custom-header-unique .logo-unique {
        float: none;
        width: auto;
        justify-content: flex-start;
    }

    .custom-header-unique .links-unique {
        float: none;
        position: fixed;
        z-index: 9;
        left: 0;
        right: 0;
        top: 140px;
        bottom: 100%;
        width: auto;
        height: auto;
        flex-direction: column;
        justify-content: space-evenly;
        background-color: rgba(0, 0, 0, .8);
        overflow: hidden;
        transition: all 0.5s ease-in-out;
    }

    .custom-header-unique .links-unique a {
        font-size: 20px;
    }

    #nav-toggle-unique:checked~.links-unique {
        bottom: 0;
    }

    .custom-header-unique .icon-burger-unique {
        display: block;
    }

    #nav-toggle-unique:checked~.icon-burger-unique .line-unique:nth-child(1) {
        transform: translateY(10px) rotate(225deg);
    }

    #nav-toggle-unique:checked~.icon-burger-unique .line-unique:nth-child(3) {
        transform: translateY(-10px) rotate(-225deg);
    }

    #nav-toggle-unique:checked~.icon-burger-unique .line-unique:nth-child(2) {
        opacity: 0;
    }
}

.small-header {
    font-size: 14px;
    background-color: #2d2b2b;
    color: #00c9ff;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.blink {
    animation: blinker 1.5s linear infinite;
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}

.small-header p {
    margin: 0;
    font-weight: bold;
    font-size: 20px;
}

.custom-header-unique.scrolled .small-header {
    animation: neonGlow 1.5s infinite alternate;
    border: 2px solid transparent;
}

@keyframes neonGlow {
    0% {
        border-color: #00c9ff;
        box-shadow: 0 0 10px rgba(0, 201, 255, 0.5);
    }

    100% {
        border-color: #0077ff;
        box-shadow: 0 0 20px rgba(0, 119, 255, 0.8);
    }
}