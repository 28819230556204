/* EnterpriceProductComponent.css */

.enterprise-component-div {
    background-color: #333;
}

.enterprise-card-wrapper {
    max-width: 1100px;
    margin: 0 auto;
}

.enterprise-img-display {
    overflow: hidden;
}

.enterprise-borderbackground {
    background: none;
    border: none;
}

.enterprise-img-showcase {
    display: flex;
    width: 100%;
    transition: all 0.5s ease;
}

.enterprise-img-showcase .enterprise-img-product {
    min-width: 100%;
}

.enterprise-img-select {
    display: flex;
}

.enterprise-img-item {
    margin: 0.3rem;
}

.enterprise-img-item:nth-child(1),
.enterprise-img-item:nth-child(2),
.enterprise-img-item:nth-child(3) {
    margin-right: 0;
}

.enterprise-img-item:hover {
    opacity: 0.8;
}

.enterprise-product-content {
    padding: 2rem 1rem;
}

.enterprise-product-title {
    font-size: 3rem;
    text-transform: capitalize;
    font-weight: 700;
    position: relative;
    color: #bfd5eb;
    margin: 1rem 0;
}

.enterprise-about-info {
    color: white;
}

.enterprise-feature {
    color: white;
}

.enterprise-component-button-container {
    display: flex;
    justify-content: center;
    height: 150px;
    min-height: 150px;
    align-items: center;
}

.component-button-download {
    background-color: white;
    color: #1a72bc;
    border: none;
    padding: 10px 40px;
    margin: 0 20px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    border-radius: 20px;
}

.component-button-download:hover {
    background-color: black;
    color: white;
    border: 2px solid blue;
}

.hyperlink-button-enterprise {
    background-color: #333;
    color: #2d73be;
    border: none;
    cursor: pointer;
    font-size: 16px;
    margin-right: 20px;
}

.hyperlink-button-enterprise:hover {

    color: white;
    text-decoration: underline;
}

.casestudy-button-enterprise {
    margin-top: 50px;
    margin-left: 20px;
}

.component-button-enquiry {
    background-color: white;
    color: #1a72bc;
    border: none;
    padding: 10px 40px;
    margin: 0 20px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    border-radius: 20px;
}

.component-button-enquiry:hover {
    background-color: black;
    color: white;
    border: 2px solid blue;
}

@media only screen and (max-width: 600px) {
    .enterprise-card-wrapper {
        padding-top: 80px;
    }

    .enterprise-component-button-container {
        margin-top: 30px;
        height: 50px;
        min-height: 50px;
    }
}

@media only screen and (min-width: 600px) {
    .enterprise-card-wrapper {
        padding-top: 150px;
    }
}

@media only screen and (min-width: 768px) {
    .enterprise-card-wrapper {
        padding-top: 150px;
    }

    .enterprise-component-button-container {
        height: 100px;
        min-height: 100px;
    }
}

@media screen and (min-width: 992px) {

    .component-button-enquiry,
    .component-button-download {
        margin-top: 100px;
    }

    .enterprise-card-product {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 1.5rem;
    }

    .enterprise-card-wrapper {
        height: 100vh;
        padding-top: 0;
        margin-top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .enterprise-product-imgs {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .enterprise-product-content {
        padding-top: 0;
    }
}