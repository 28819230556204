.ManufacturingBackground {
    width: 100%;
    height: 100vh;
    float: left;
    position: relative;
    top: 40px;
    left: 0;
    right: 0;
    bottom: 0;
    padding: none;
    object-fit: cover;
    z-index: -1;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.fade-in {
    animation: fadeIn 500ms ease-in-out;
}


.fade-in {
    animation: fadeIn 500ms ease-in-out;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@media (max-width: 768px) {
    .ManufacturingBackground {

        height: 70vh;
    }

    .ManufacturingHero-dot-play {
        top: -150px;
        position: relative;
    }
}