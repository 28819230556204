/* EnterpriseHeader.css */

.custom-header {
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 10px 5%;
    transition: all 0.3s ease;
}

.custom-header.scrolled {
    background-color: #0e0c0c;
    animation: neonGlow 1.5s infinite alternate, backgroundAnimation 10s infinite linear;
}

/* Logo styles */
.logo {
    display: flex;
    align-items: center;
    font-size: 24px;
    color: #fff;
}

.logo img {
    height: 70px;
    margin-right: 10px;
}

.logo strong {
    display: none;
}

/* Navigation styles */
.navigation {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.links {
    list-style: none;
    display: flex;
}

.links li {
    margin-right: 15px;
}

.links a {
    font-size: 20px;
    font-weight: bold;
    color: #fff;
    text-decoration: none;
    transition: color 0.3s ease;
}

.links a:hover {
    color: #00c9ff;
}

/* Hamburger menu icon */
.nav-toggle {
    display: none;
}

.icon-burger {
    display: none;
}

.icon-burger .line {
    width: 30px;
    height: 5px;
    background-color: #fff;
    margin: 5px;
    border-radius: 3px;
    transition: all 0.5s ease-in-out;
}

/* Media queries for responsive design */
@media screen and (max-width: 1024px) {
    .logo {
        font-size: 22px;
    }

    .logo img {
        height: 60px;
    }

    .navigation {
        justify-content: flex-end;
    }
}

@media screen and (max-width: 768px) {

    /* Hide 'CALIDTECH' on smaller screens */
    .logo strong {
        display: inline;
    }

    /* Hide navigation links by default on smaller screens */
    .links {
        display: none;
        flex-direction: column;
        position: absolute;
        top: 60px;
        left: 0;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.8);
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        transition: all 0.3s ease;
    }

    #nav-toggle:checked~.links {
        display: flex;
    }

    .nav-toggle {
        display: block;
        cursor: pointer;
    }

    .icon-burger {
        display: block;
        cursor: pointer;
    }

    /* Animation for hamburger menu icon */
    #nav-toggle:checked~.icon-burger .line:nth-child(1) {
        transform: translateY(10px) rotate(225deg);
    }

    #nav-toggle:checked~.icon-burger .line:nth-child(3) {
        transform: translateY(-10px) rotate(-225deg);
    }

    #nav-toggle:checked~.icon-burger .line:nth-child(2) {
        opacity: 0;
    }

    .links a {
        padding: 10px;
    }
}