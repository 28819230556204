.LandingPageCaseStudiesDiv {
    background-color: #252525;
}

.custom-category-name {
    text-align: center;
    font-size: 40px;
    color: #eee;
    padding: 20px;
    background-color: #252525;
}

.custom-card-category-2 ul,
.custom-card-category-3 ul,
.custom-card-category-4 ul,
.custom-card-category-5 ul .custom-card-category-6 ul {
    padding: 0;
}

.custom-card-category-2 ul li,
.custom-card-category-3 ul li,
.custom-card-category-4 ul li,
.custom-card-category-5 ul li,
.custom-card-category-6 ul li {
    list-style-type: none;
    display: inline-block;
    vertical-align: top;
}

.custom-card-category-2 ul li,
.custom-card-category-3 ul li {
    margin: 10px 5px;
}

.custom-card-category-1,
.custom-card-category-2,
.custom-card-category-3,
.custom-card-category-4,
.custom-card-category-5,
.custom-card-category-6 {
    padding-bottom: 45px;
    text-align: center;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.custom-card-category-1 {

    background-color: #252525;
}

.custom-card-category-1 div,
.custom-card-category-2 div {
    display: inline-block;
}

.custom-card-category-1>div,
.custom-card-category-2>div:not(:last-child) {
    margin: 10px 5px;
    text-align: left;
    border-radius: 25px;
}

.custom-card-category-1>div:hover {
    border: 1px solid #00c9ff;
    box-shadow: 2px 2px 12px rgba(0, 98, 184, 0.445);
    /* scale animation for cards */
    transform: scale(1.05);
}

/* Basic Card */
.custom-basic-card {
    width: 300px;
    position: relative;

    -webkit-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.3);
    -o-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.3);
}

.custom-basic-card .custom-card-content {
    padding: 30px;
}

.custom-basic-card .custom-card-title {
    font-size: 25px;
}

.custom-basic-card .custom-card-text {
    line-height: 1.6;
}

.custom-basic-card .custom-card-link {
    padding: 25px;
    width: -webkit-fill-available;
}

.custom-basic-card .custom-card-link a {
    text-decoration: none;
    position: relative;
    padding: 10px 0px;
}

.custom-basic-card .custom-card-link a:after {
    top: 30px;
    content: "";
    display: block;
    left: 50%;
    position: absolute;
    width: 0;

    -webkit-transition: width 0.3s ease 0s, left 0.3s ease 0s;
    -moz-transition: width 0.3s ease 0s, left 0.3s ease 0s;
    -o-transition: width 0.3s ease 0s, left 0.3s ease 0s;
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
}

.custom-basic-card .custom-card-link a:hover:after {
    width: 100%;
    left: 0;
    border: 1px solid #00c9ff;
    box-shadow: 2px 2px 12px rgba(0, 98, 184, 0.445);
}


.custom-basic-card-aqua {
    background-image: linear-gradient(to bottom right, #00bfad, #99a3d4);
}

.custom-basic-card-aqua .custom-card-content,
.custom-basic-card .custom-card-link a {
    color: #fff;
}

.custom-basic-card-aqua .custom-card-link {
    border-top: 1px solid #82c1bb;
}

.custom-basic-card-aqua .custom-card-link a:after {
    background: #fff;
}

.custom-basic-card-lips {
    background-image: linear-gradient(to bottom right, #ec407b, #ff7d94);
}

.custom-basic-card-lips .custom-card-content {
    color: #fff;
}

.custom-basic-card-lips .custom-card-link {
    border-top: 1px solid #ff97ba;
}

.custom-basic-card-lips .custom-card-link a:after {
    background: #fff;
}

.custom-basic-card-light {
    border: 1px solid #eee;
}

.custom-basic-card-light .custom-card-title,
.custom-basic-card-light .custom-card-link a {
    color: #636363;
}

.custom-basic-card-light .custom-card-text {
    color: #7b7b7b;
}

.custom-basic-card-light .custom-card-link {
    border-top: 1px solid #eee;
}

.custom-basic-card-light .custom-card-link a:after {
    background: #636363;
}

.custom-basic-card-dark {
    background-image: linear-gradient(to bottom right, #252525, #4a4a4a);
}

.custom-basic-card-dark .custom-card-title,
.custom-basic-card-dark .custom-card-link a {
    color: #eee;
}

.custom-basic-card-dark .custom-card-text {
    color: #dcdcdcdd;
}

.custom-basic-card-dark .custom-card-link {
    border-top: 1px solid #636363;
}

.custom-basic-card-dark .custom-card-link a:after {
    background: #eee;
}