/* ServiceCubeService.css */

.ServiceApp {
  text-align: center;
}

.ent-header {
  height: 95vh;
  background: linear-gradient(rgba(0, 0, 0, 0.55), rgba(0, 0, 0, 0.55)),
    url(../../assets/images/programming-background-with-html.jpg);
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ent-header-text {
  width: 500px;
  text-align: center;
}

.ent-header h1 {
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 45px;
  color: white;
}

#service-photography {
  font-size: 60px;
  letter-spacing: 0.5px;
}

.ent-header p {
  margin-bottom: 20px;
  color: white;
  font-size: 18px;
}

.ent-header button {
  border: 2px solid white;
  color: white;
  padding: 10px;
  font-size: 18px;
  width: 160px;
  border-radius: 500px;
  cursor: pointer;
  background-color: transparent;
  transition: all 0.2s ease-in-out;
}

.ent-header button:hover,
button:active {
  background-color: white;
  color: black;
}

.center {
  display: block;
  margin: 0 auto;
}

/* */

@media screen and (max-width: 676px) {
  .ent-header-text {
    width: 95%;
    text-align: center;
  }
}

@media screen and (max-width: 601px) {
  #service-photography {
    font-size: 45px;
  }

  .ent-header h1 {
    font-size: 30px;
  }

  .ent-header p {
    font-size: 15px;
    width: 85%;
    display: block;
    margin: auto;
    margin-bottom: 20px;
  }

  .ent-header button {
    font-size: 16px;
    width: 140px;
  }
}

@media screen and (max-width: 460px) {
  #service-photography {
    font-size: 35px;
  }

  .ent-header h1 {
    font-size: 28px;
  }
}