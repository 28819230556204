.contact-container {
    position: relative;
    width: 100%;
    min-height: 100vh;
    padding: 2rem;
    background-color: #000000;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.contacts-form {
    top: 50px;
    position: relative;
    align-items: center;
    justify-content: center;
    z-index: 0;
    width: 100%;
    max-width: 820px;
    background-color: #000000;
    border-radius: 10px;
    box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    border: 2px solid #211abc;
}

.contact-form {
    background-color: #1a72bc;
    position: relative;
}

.circle {
    border-radius: 50%;
    background: linear-gradient(135deg, transparent 20%, #149279);
    position: absolute;
}

.circle.one {
    width: 130px;
    height: 130px;
    top: 130px;
    right: -40px;
}

.circle.two {
    width: 80px;
    height: 80px;
    top: 10px;
    right: 30px;
}

.contact-form:before {
    content: "";
    position: absolute;
    width: 26px;
    height: 26px;
    background-color: #1a72bc;
    transform: rotate(45deg);
    top: 50px;
    left: -13px;
}

.input-part-form {
    padding: 2.3rem 2.2rem;
    z-index: 10;
    overflow: hidden;
    position: relative;
}

.title {
    color: #fff;
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 1;
    margin-bottom: 2.7rem;
}

.input-container {
    position: relative;
    margin: 1rem 0;
}

.input {
    width: 100%;
    outline: none;
    border: 2px solid #fafafa;
    background: none;
    padding: 0.6rem 1.2rem;
    color: #fff;
    font-weight: 500;
    font-size: 0.95rem;
    letter-spacing: 0.5px;
    border-radius: 5px;
    transition: 0.3s;
}

textarea.input {
    padding: 0.8rem 1.2rem;
    min-height: 150px;
    border-radius: 5px;
    resize: none;
    overflow-y: auto;
}

.input-container label {
    position: absolute;
    top: 50%;
    left: 15px;
    transform: translateY(-50%);
    padding: 0 0.4rem;
    color: #fafafa;
    font-size: 0.9rem;
    font-weight: 400;
    pointer-events: none;
    z-index: 1000;
    transition: 0.5s;
}

.input-container.textarea label {
    top: 1rem;
    transform: translateY(0);
}

.btn {
    padding: 0.6rem 1.3rem;
    background-color: #fff;
    border: 2px solid #fafafa;
    font-size: 0.95rem;
    color: #1a72bc;
    line-height: 1;
    border-radius: 5px;
    outline: none;
    cursor: pointer;
    transition: 0.3s;
    margin: 0;
    width: 100%;
}

.btn:hover {
    background-color: transparent;
    color: #fff;
}

.input-container span {
    position: absolute;
    top: 0;
    left: 25px;
    transform: translateY(-50%);
    font-size: 0.8rem;
    padding: 0 0.4rem;
    color: transparent;
    pointer-events: none;
    z-index: 500;
}

.input-container span:before,
.input-container span:after {
    content: "";
    position: absolute;
    width: 10%;
    opacity: 0;
    transition: 0.3s;
    height: 5px;
    background-color: #1abc9c;
    top: 50%;
    transform: translateY(-50%);
}

.input-container span:before {
    left: 50%;
}

.input-container span:after {
    right: 50%;
}

.input-container.focus label {
    top: 0;
    transform: translateY(-50%);
    left: 25px;
    font-size: 0.8rem;
}

.input-container.focus span:before,
.input-container.focus span:after {
    width: 50%;
    opacity: 1;
}

.contact-info {
    padding: 2.3rem 2.2rem;
    position: relative;
}

.contact-info .title {
    color: #0056b3;
}

.text {
    color: #f4f4f4;
    margin: 1.5rem 0 2rem 0;
}

.information {
    display: flex;
    color: #555;
    margin: 0.7rem 0;
    align-items: center;
    font-size: 0.95rem;
    gap: 10px;
}

.information i {
    color: #1ABC9C;
}

.information p {
    color: #f4f4f4;
}

.icon {
    width: 28px;
    margin-right: 0.7rem;
}

.social-media {
    padding: 2rem 0 0 0;
}

.social-media p {
    color: #333;
}

.social-icons {
    display: flex;
    margin-top: 0.5rem;
}

.social-icons a {
    width: 35px;
    height: 35px;
    border-radius: 5px;
    background: linear-gradient(45deg, #1abc9c, #149279);
    color: #fff;
    text-align: center;
    line-height: 35px;
    margin-right: 0.5rem;
    transition: 0.3s;
}

.social-icons a:hover {
    transform: scale(1.05);
}

.contact-info:before {
    content: "";
    position: absolute;
    width: 110px;
    height: 100px;
    border: 22px solid #1a67bc;
    border-radius: 50%;
    bottom: -77px;
    right: 50px;
    opacity: 0.3;
}

.big-circle {
    position: absolute;
    width: 500px;
    height: 500px;
    border-radius: 50%;
    background: linear-gradient(to bottom, #1c29d4, #15909b);
    bottom: 50%;
    right: 50%;
    transform: translate(-40%, 38%);
}

.big-circle:after {
    content: "";
    position: absolute;
    width: 360px;
    height: 360px;
    background-color: #000000;
    border-radius: 50%;
    top: calc(50% - 180px);
    left: calc(50% - 180px);
}

.square {
    position: absolute;
    height: 400px;
    top: 50%;
    left: 50%;
    transform: translate(181%, 11%);
    opacity: 0.2;
}

@media (max-width: 850px) {
    .contacts-form {
        grid-template-columns: 1fr;
    }

    .contact-info:before {
        bottom: initial;
        top: -75px;
        right: 65px;
        transform: scale(0.95);
    }

    .contact-form:before {
        top: -13px;
        left: initial;
        right: 70px;
    }

    .square {
        transform: translate(140%, 43%);
        height: 350px;
    }

    .big-circle {
        bottom: 75%;
        transform: scale(0.9) translate(-40%, 30%);
        right: 50%;
    }

    .text {
        margin: 1rem 0 1.5rem 0;
    }

    .social-media {
        padding: 1.5rem 0 0 0;
    }
}

@media (max-width: 480px) {
    .contact-container {
        padding: 1.5rem;
    }

    .contact-info:before {
        display: none;
    }

    .square,
    .big-circle {
        display: none;
    }

    .input-part-form,
    .contact-info {
        padding: 1.7rem 1.6rem;
    }

    .text,
    .information,
    .social-media p {
        font-size: 0.8rem;
    }

    .title {
        font-size: 1.15rem;
    }

    .social-icons a {
        width: 30px;
        height: 30px;
        line-height: 30px;
    }

    .icon {
        width: 23px;
    }

    .input {
        padding: 0.45rem 1.2rem;
    }

    .btn {
        padding: 0.45rem 1.2rem;
    }
}

/* input box animation */

.input-container {
    position: relative;
    margin-bottom: 25px;
}

.input {
    width: 100%;
    padding: 10px;
    outline: none;
    border: none;
    background-color: #f2f2f2;
    color: #333;
    border-radius: 20px;
}

.input-container label {
    position: absolute;
    top: 50%;
    left: 10px;
    color: #8e8e8e;
    transform: translateY(-50%);
    transition: all 0.3s ease;
    pointer-events: none;
}

.input-container label.label-active {
    top: 5px;
    font-size: 12px;
    color: #333;
}

.input-container span {
    position: absolute;
    bottom: 10px;
    left: 10px;
    color: #8e8e8e;
    transition: all 0.3s ease;
}

.textarea .input {
    height: 100px;
    resize: none;
}

.contact_us {
    background-color: #f1f1f1;
    padding: 120px 0px;
}

.contact_inner {
    background-color: #fff;
    position: relative;
    box-shadow: 20px 22px 44px #cccc;
    border-radius: 25px;
}

.contact_field {
    padding: 60px 340px 90px 100px;
}

.contact_field h3 {
    color: #000;
    font-size: 40px;
    letter-spacing: 1px;
    font-weight: 600;
    margin-bottom: 10px;
}

.contact_field p {
    color: #000;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 1px;
    margin-bottom: 35px;
}

.contact_field .form-control {
    border-radius: 0px;
    border: none;
    border-bottom: 1px solid #ccc;
}

.contact_field .form-control:focus {
    box-shadow: none;
    outline: none;
    border-bottom: 2px solid #1325e8;
}

.contact_field .form-control::placeholder {
    font-size: 13px;
    letter-spacing: 1px;
}

.contact_info_sec {
    position: absolute;
    background-color: #2d2d2d;
    right: 1px;
    top: 18%;
    height: 340px;
    width: 340px;
    padding: 40px;
    border-radius: 25px 0 0 25px;
}

.contact_info_sec h4 {
    letter-spacing: 1px;
    padding-bottom: 15px;
}

.info_single {
    margin: 30px 0px;
}

.info_single i {
    margin-right: 15px;
}

.info_single span {
    font-size: 14px;
    letter-spacing: 1px;
}

.map_sec {
    padding: 50px 0px;
}

.map_inner h4,
.map_inner p {
    color: #000;
    text-align: center;
}

.map_inner p {
    font-size: 16px;
}

.map_bind {
    margin-top: 50px;
    border-radius: 30px;
    overflow: hidden;
}

.map_inner {
    background-color: #353535;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.map_inner h4 {
    font-size: 24px;
    font-weight: bold;
    color: #ffffff;
    margin-bottom: 20px;
    transition: color 0.3s ease-in-out;
}

.map_inner p {
    color: #ffffff;
    text-align: center;
    transition: color 0.3s ease-in-out;
}

.map_inner:hover h4 {
    color: #007bff;
}

.map_inner:hover p {
    color: #afaaaa;
}

.map_bind {
    width: 100%;
    height: 450px;
    position: relative;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.map_bind iframe {
    width: 100%;
    height: 100%;
    border: 0;
    transition: transform 0.3s ease-in-out;
}

/* EntContactComponent.css */

.scroll-button {
    display: inline-block;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.scroll-button:hover {
    background-color: black;
}

.scroll-button.clicked {
    animation: pulseAnimation 0.5s linear;
}

/* Keyframe Animation for Click Effect */
@keyframes pulseAnimation {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.1);
    }

    100% {
        transform: scale(1);
    }
}