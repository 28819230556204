/* EnterpriseServicePageNavBar.css */

#enterprise-service-navbar {
    background-color: #333;
    color: white;
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1050;
    margin-top: 140px;
    position: static !important;
}

.enterprise-mobile-menu-icon-service {
    display: none;
    font-size: 2rem;
    background: none;
    border: none;
    color: white;
    cursor: pointer;
}

.enterprise-nav-categories-service {
    list-style: none;
    padding: 0;
    display: flex;
    gap: 1rem;
}

.enterprise-li-navbar {
    position: relative;
    transition: background-color 0.3s;
}

.enterprise-navbar-category-name {
    background: none;
    border: none;
    color: white;
    cursor: pointer;
    text-decoration: none;
    padding: 0.5rem 1rem;
    display: block;
}

.enterprise-subcategory-name-navbar {
    background: none;
    border: none;
    color: white;
    cursor: pointer;
    text-decoration: none;
    padding: 0.5rem 1rem;
    display: block;
}

.enterprise-subcategories-navbar {
    display: none;
    list-style: none;
    padding: 0;
    position: absolute;
    left: 0;
    background-color: #444;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s ease;
    transform: translateY(-20px);
    border-radius: 4px;
}

.enterprise-li-navbar:hover .enterprise-subcategories-navbar,
.enterprise-li-navbar:focus-within .enterprise-subcategories-navbar {
    display: block;
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
}

.enterprise-subcategories-navbar #enterprise-button-navbar-service {
    display: block;
    color: white;
    background: none;
    border: none;
    text-align: left;
    padding: 0.5rem 1rem;
    width: 100%;
    cursor: pointer;
    transition: background-color 0.3s;
}

.enterprise-subcategories-navbar #enterprise-button-navbar-service:hover {
    background-color: #555;
}

@media (max-width: 768px) {
    #enterprise-service-navbar {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        position: fixed !important;
        top: 0;
        left: 0;
        transform: translateX(-100%);
        width: 75%;
        max-width: 300px;
        height: 100vh;
        padding: 20px;
        box-shadow: 2px 0 5px rgba(0, 0, 0, 0.5);
        transition: transform 0.3s ease;
        z-index: 1050;
    }

    #enterprise-service-navbar.active-enterprise-service {
        transform: translateX(0);
    }

    .enterprise-mobile-menu-icon-service {
        display: block;
        position: absolute;
        top: 20px;
        right: -50px;
        z-index: 2;
        background-color: #333;
        padding: 11px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
    }

    .enterprise-nav-categories-service {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .enterprise-li-navbar .enterprise-subcategories-navbar {
        position: static;
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
        display: none;
        width: 100%;
    }

    .enterprise-li-navbar.active-enterprise-service .enterprise-subcategories-navbar {
        display: block;
    }
}