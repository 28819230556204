/* SplashScreen.css */

@keyframes growLogo {
  from {
    transform: scale(0.5);
  }

  to {
    transform: scale(50);
  }
}

.splash-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: #ffffff;
}

.logo {
  max-width: 100%;
  height: auto;
  animation-name: growLogo;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
}

@media (min-width: 768px) {
  @keyframes growLogo {
    from {
      transform: scale(1);
    }

    to {
      transform: scale(4);
    }
  }

  .logo {
    width: 150px;
  }
}

@media (max-width: 480px) {
  @keyframes growLogo {
    from {
      transform: scale(0.3);
    }

    to {
      transform: scale(4);
    }
  }

  .logo {
    width: 80px;
  }
}