/* ProductCategory.css */

@import url('https://fonts.googleapis.com/css?family=Roboto');

.manuprodcategory {
    /* background-color: #0e0c0c; */
    background-color: #343434;
}

.manuprodheadline {
    background-color: #0e0c0c;
}

h1 {
    text-align: center;
}

.wrapper {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    background-color: #343434;
}

.solution-offering{
    background-color: #343434;
}

.card {
    max-width: 286px;
    min-height: 250px;
    padding: 30px;
    border-radius: 15px;
    box-sizing: border-box;
    color: #FFF;
    margin: 20px;
    background-image: linear-gradient(to bottom right, #252525, #4a4a4a);
    box-shadow: rgb(50 50 93 / 25%) 0px 30px 60px -12px, rgb(0 0 0 / 30%) 0px 18px 36px -18px;
    transition: border-color 0.5s, box-shadow 0.5s, color 0.5s;
    border: 2px solid transparent;
}

.card:hover {
    border: 1px solid #00c9ff;
    box-shadow: 2px 2px 12px rgba(0, 98, 184, 0.445);
    /* scale animation for cards */
    transform: scale(1.05);
}

.card-title1 {
    margin-top: 0;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 1.2px;
    background-color: #00c9ff;
    color: black;
    padding: 10px;
    border-radius: 5px;
    text-align: center;
    height: 80px;
    justify-content: center;
    display: grid;
    place-items: center;
}

.card-content {
    font-size: 14px;
    letter-spacing: 0.5px;
    line-height: 1.5;
}

.card-btn {
    all: unset;
    display: block;
    margin-left: auto;
    border: 2px solid #FFF;
    padding: 10px 15px;
    border-radius: 25px;
    font-size: 10px;
    font-weight: 600;
    transition: all 0.5s;
    cursor: pointer;
    letter-spacing: 1.2px;
    color: white;
}

.card-btn:hover {
    background: #00c9ff;
    border: 00c9ff;
}

.card-content {
    font-size: 14px;
    letter-spacing: 0.5px;
    line-height: 1.5;
    color: white;
}

.card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.card-btn {
    margin-top: auto;
}