.ManuDividingIcons-DividingIcons {
    position: fixed;
    top: 200px;
    right: -15px;
    z-index: 1;
}

.ManuDividingIcons-DividingIcons-btn {
    gap: 10px;
    display: flex;
    width: 215px;
    align-items: center;
    justify-content: space-between;
    padding-right: 30px;
    padding-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    border: 1px;
    border-bottom-left-radius: 30px;
    border-top-left-radius: 30px;
    margin-bottom: 5px;
    position: relative;
    left: 145px;
    transition: left 1s;
    border: 2px solid #00ffff;
    height: 68px;
}

.ManuDividingIcons-DividingIcons-btn:hover {
    left: 0px;
    transition: left 1s;
    margin-right: 10px;
}

.ManuDividingIcons-DividingIcons-btn img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.ManuDividingIcons-DividingIcons-btn p {
    color: white;
    margin-top: 0;
    margin-bottom: 0;
}

.ManuDividingIcons-color-telegram {
    background-color: #353333;
}

.ManuDividingIcons-google-font {
    font-size: -3.75rem;
}

.ManuDividingIcons-icons8-telegram-app,
.ManuDividingIcons-icons8-instagram,
.ManuDividingIcons-icons8-whatsapp {
    order: 2;
    display: inline-block;
    width: 40px;
    height: 40px;
    background-size: 100%;
}

@media (max-width: 768px) {
    .ManuDividingIcons-DividingIcons-btn img {
        width: 20px;
        height: 20px;
        border-radius: 50%;
    }

    .ManuDividingIcons-DividingIcons-btn {
        width: 195px;
        height: 30px;
    }

    .ManuDividingIcons-google-font {
        font-size: 10px;
    }

    .ManuDividingIcons-DividingIcons {
        top: 440px;
    }

}