/* ManufacturingWarehouseManagementSubservicePage.css */

.manufacturing-servive-des {
  background-color: #1a1a1a;
  border-radius: 8px;
  padding: 20px;
  margin: 20px 30px;
  color: white;
  line-height: 1.6;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  text-align: center;
}

.manufacturing-servive-des:hover {
  transform: scale(1.02);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

.manufacturing-service-page {
  background-color: black;
}

.subservice-header-warehouse {
  padding-top: 100px;
  width: 100%;
  height: 95vh;
  background: linear-gradient(rgba(0, 0, 0, 0.55), rgba(0, 0, 0, 0.55)),
    url('../../../assets/images/warehouse.jpeg');
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.subheader-text {
  width: 700px;
  text-align: center;
}

.subservice-header-warehouse .subservice-header-heading {
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 45px;
  color: white;
}

#subservice-header-subheading {
  font-size: 60px;
  letter-spacing: 0.5px;
}

.subservice-header-warehouse .subservice-header-description {
  margin-bottom: 20px;
  color: white;
  font-size: 18px;
}

.subservice-header-warehouse .subservice-header-button {
  border: 2px solid white;
  color: white;
  padding: 10px;
  font-size: 18px;
  width: 160px;
  border-radius: 500px;
  cursor: pointer;
  background-color: transparent;
  transition: all 0.2s ease-in-out;
}

.subservice-header-warehouse .subservice-header-button:hover,
.subservice-header-button:active {
  background-color: white;
  color: black;
}

.Downloadpdf {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 12px 24px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.Downloadpdf:hover {
  background-color: #0056b3;
  transform: translateY(-2px);
}

.Downloadpdf:active {
  background-color: #004085;
  transform: translateY(0);
}

.Downloadpdf:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5);
}

/* Media queries for responsiveness */
@media screen and (max-width: 676px) {
  .subheader-text {
    width: 95%;
    text-align: center;
  }
}

@media screen and (max-width: 601px) {
  #subservice-header-subheading {
    font-size: 45px;
  }

  .subservice-header-warehouse .subservice-header-heading {
    font-size: 30px;
  }

  .subservice-header-warehouse .subservice-header-descriptionp {
    font-size: 15px;
    width: 85%;
    display: block;
    margin: auto;
    margin-bottom: 20px;
  }

  .subservice-header-warehouse .subservice-header-button {
    font-size: 16px;
    width: 140px;
  }
}

@media screen and (max-width: 460px) {
  #subservice-header-subheading {
    font-size: 35px;
  }

  .subservice-header-warehouse .subservice-header-heading {
    font-size: 28px;
  }
}


/* middle css */


.headline-container {
  text-align: center;
  padding: 20px;
  background-color: #f4f4f4;
  /* Background color */
  border: 1px solid #ccc;
  /* Border color */
  border-radius: 8px;
  /* Rounded corners */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  /* Shadow effect */
}

.headline-title {
  font-size: 2.5rem;
  margin-bottom: 10px;
  color: white;
}

.headline-description {
  font-size: 1.2rem;
  color: #666;
}

@media (max-width: 768px) {
  .headline-title {
    font-size: 2rem;
  }

  .headline-description {
    font-size: 1rem;
  }
}

/* end css */

.subservice-card-div-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 100px 50px;
  padding: 100px 50px;
}

.subservice-card-div-container .subservice-card-div {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 350px;
  max-width: 100%;
  height: 300px;
  background: #FFF;
  border-radius: 20px;
  transition: 0.5s;
  box-shadow: 0 35px 80px rgba(0, 0, 0, 0.15);
}

.subservice-card-div-container .subservice-card-div:hover {
  height: 400px;
}

.subservice-card-div-container .subservice-card-div .subservice-card-img-box {
  position: absolute;
  top: 20px;
  width: 300px;
  height: 220px;
  border-radius: 12px;
  overflow: hidden;
  transition: 0.5s;
}

.subservice-card-div-container .subservice-card-div:hover .subservice-card-img-box {
  top: -100px;
  scale: 0.75;
  box-shadow: 0 15px 45px rgba(0, 0, 0, 0.2);
}

.subservice-card-div-container .subservice-card-div .subservice-card-img-box img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.subservice-card-div-container .subservice-card-div .subservice-card-div-content {
  position: absolute;
  top: 252px;
  width: 100%;
  height: 35px;
  padding: 0 30px;
  text-align: center;
  overflow: hidden;
  transition: 0.5s;
}

.subservice-card-div-container .subservice-card-div:hover .subservice-card-div-content {
  top: 130px;
  height: 250px;
}

.subservice-card-div-container .subservice-card-div .subservice-card-div-content .subservice-card-div-heading {
  font-size: 1.5rem;
  font-weight: 700;
  color: var(--clr);
}

.subservice-card-div-container .subservice-card-div .subservice-card-div-content .subservice-card-div-description {
  color: #333;
}

.subservice-card-div-container .subservice-card-div .subservice-card-div-content a {
  position: relative;
  top: 15px;
  display: inline-block;
  padding: 12px 25px;
  text-decoration: none;
  background: var(--clr);
  color: #FFF;
  font-weight: 500;
}

.subservice-card-div-container .subservice-card-div .subservice-card-div-content a:hover {
  opacity: 0.8;
}

@media (max-width: 480px) {
  .subservice-card-div-container .subservice-card-div {
    width: 230px;
    border-radius: 15px;
  }

  .subservice-card-div-container .subservice-card-div .subservice-card-img-box img {
    border-radius: 10px;
  }

  .subservice-card-div-container .subservice-card-div .subservice-card-div-content .subservice-card-div-description {
    font-size: 0.8rem;
  }

  .subservice-card-div-container .subservice-card-div .subservice-card-div-content a {
    font-size: 0.9rem;
  }
}