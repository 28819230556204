.jobDiv {
    background-color: #333;
}

.job-posting-parent {

    padding-top: 200px;
}

.job-posting {
    padding: 50px;
    border-radius: 8px;
    background-color: #f8f9fa;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    animation: fadeIn 0.5s ease;
    transition: transform 0.2s ease;
    max-width: 600px;
    margin: 0 auto;
    margin-bottom: 80px;
}

.job-header {
    text-align: center;
    margin-bottom: 20px;
}

.job-title {
    font-size: 32px;
    color: #007bff;
    margin-bottom: 10px;
}

.job-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.detail {
    font-size: 18px;
}

.apply-button {
    padding: 12px 24px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

.apply-button:hover {
    background-color: #0056b3;
}

.job-description {
    margin-bottom: 20px;
}

.job-skills {
    padding-top: 20px;
    border-top: 1px solid #ccc;
}

.job-skills h2 {
    font-size: 24px;
    color: #333;
    margin-bottom: 10px;
}

.job-skills ul {
    list-style-type: none;
    padding: 0;
}

.job-skills li {
    margin-bottom: 8px;
    font-size: 16px;
}

/* Animation */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@media (max-width: 768px) {
    .job-details {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .detail {
        margin-bottom: 10px;
    }

    .apply-button {
        width: 100%;
        margin-top: 12px;
    }
}

@media (min-width: 769px) {
    .job-details {
        justify-content: flex-start;
    }

    .detail {
        margin-right: 20px;
    }
}