/* ManufacturingTeltonikaSubcategoryPage.css */

.manufacturing-small-heder-navbar {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    color: #00c9ff;
    background-color: #414040;
}

.teltonika-subproduct-nav {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.teltonika-subproduct-nav-button {
    background-color: white;
    color: #1a72bc;
    border: none;
    padding: 10px 20px;
    margin: 0 10px 10px 0;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease, color 0.3s ease;
    flex: 1 1 calc(50% - 10px);
    box-sizing: border-box;
}

.teltonika-subproduct-nav-button:hover {
    background-color: black;
    color: white;
    border: 2px solid blue;
}

.teltonika-subproduct-nav-button.active-teltonika-subproduct {
    background-color: #1a72bc;
    color: white;
    border: 2px solid #1a72bc;
    cursor: default;
    pointer-events: none;
}

.teltonika-page {
    background-color: #333;
    color: white;
}

.pagination {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.pagination .teltonika-ul {
    list-style: none;
    padding: 0;
    display: flex;
    align-items: center;
}

.teltonika-button {
    background-color: white;
    color: #1a72bc;
    border: none;
    padding: 10px 10px;
    margin: 0 5px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.teltonika-button:hover {
    background-color: black;
    color: white;
    border: 2px solid blue;
}

.active-teltonika .teltonika-button {
    background-color: #1a72bc;
    color: white;
    border: 2px solid #1a72bc;
    cursor: default;
    pointer-events: none;
}

@media only screen and (max-width: 600px) {
    .manufacturing-small-heder-navbar {
        margin-top: 140px;
    }

    .teltonika-subproduct-nav {
        flex-direction: row;
        flex-wrap: wrap;
    }

    .teltonika-subproduct-nav-button {
        margin: 10px 5px;
        flex: 1 1 calc(50% - 10px);
    }
}

@media only screen and (min-width: 600px) {
    .teltonika-subproduct-nav {
        margin-top: 20px;
        flex-direction: row;
        flex-wrap: nowrap;
    }

    .teltonika-subproduct-nav-button {
        margin: 0 10px;
        width: auto;
        text-align: center;
        flex: none;
    }
}

@media only screen and (min-width: 768px) {
    .teltonika-subproduct-nav {
        margin-top: 20px;
        flex-direction: row;
        flex-wrap: nowrap;
    }

    .teltonika-subproduct-nav-button {
        margin: 0 10px;
        width: auto;
        text-align: center;
        flex: none;
    }
}

@media screen and (min-width: 992px) {
    .teltonika-subproduct-nav {
        margin-top: 20px;
        flex-direction: row;
        flex-wrap: nowrap;
    }

    .teltonika-subproduct-nav-button {
        margin: 0 10px;
        width: auto;
        text-align: center;
        flex: none;
    }
}

@media screen and (min-width: 601px) and (max-width: 768px) {
    .manufacturing-small-heder-navbar {
        margin-top: 140px;
    }

    .teltonika-subproduct-nav {
        flex-direction: row;
        flex-wrap: wrap;
    }

    .teltonika-subproduct-nav-button {
        margin: 10px 5px;
        flex: 1 1 calc(50% - 10px);
    }
}