/* ManufacturingCategoryPageNavBar.css*/

#manufacturing-navbar {
  background-color: #333;
  color: white;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1050;
  margin-top: 140px;
  position: static !important;
}

.mobile-menu-icon {
  display: none;
  font-size: 2rem;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
}

.manufacturing-nav-categories {
  list-style: none;
  padding: 0;
  display: flex;
  gap: 1rem;
}

.manufacturing-li-navbar {
  position: relative;
  transition: background-color 0.3s;
}

.manufacturing-navbar-category-name {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  text-decoration: none;
  padding: 0.5rem 1rem;
  display: block;
  font-size: 16px;
}

.manufacturing-subcategory-name-navbar {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  text-decoration: none;
  padding: 0.5rem 1rem;
  display: block;
  font-size: 16px;
}

.manufacturing-subcategories-navbar {
  display: none;
  list-style: none;
  padding: 0;
  position: absolute;
  left: 0;
  background-color: #444;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s ease;
  transform: translateY(-20px);
  border-radius: 4px;
  position: absolute;
  z-index: 1050;
}

.manufacturing-li-navbar:hover .manufacturing-subcategories-navbar,
.manufacturing-li-navbar:focus-within .manufacturing-subcategories-navbar {
  display: block;
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.manufacturing-subcategories-navbar #manufacturing-button-navbar {
  display: block;
  color: white;
  background: none;
  border: none;
  text-align: left;
  padding: 0.5rem 1rem;
  width: 100%;
  cursor: pointer;
  transition: background-color 0.3s;
}

.manufacturing-subcategories-navbar #manufacturing-button-navbar:hover {
  background-color: #555;
}

@media (max-width: 768px) {
  #manufacturing-navbar {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    position: fixed !important;
    top: 0;
    left: 0;
    transform: translateX(-100%);
    width: 75%;
    max-width: 300px;
    height: 100vh;
    padding: 20px;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.5);
    transition: transform 0.3s ease;
    z-index: 1050;
  }

  #manufacturing-navbar.active-manufacturing {
    transform: translateX(0);
  }

  .mobile-menu-icon {
    display: block;
    position: absolute;
    top: -5px;
    right: -50px;
    z-index: 2;
    background-color: #333;
    padding: 11px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .manufacturing-nav-categories {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .manufacturing-li-navbar .manufacturing-subcategories-navbar {
    position: static;
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    display: none;
    width: 100%;
  }

  .manufacturing-li-navbar.active-manufacturing .manufacturing-subcategories-navbar {
    display: block;
  }
}