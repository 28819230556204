.whatsapp-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.whatsapp-popup {
    background-color: #ffffff;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 90%;
    max-width: 400px;
    box-sizing: border-box;
    position: relative;
}

.whatsapp-popup h2 {
    margin: 0 0 20px 0;
    font-size: 20px;
    color: #333;
}

.whatsapp-popup-input {
    display: flex;
    flex-direction: column;
}

.whatsapp-popup .phone-input {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.whatsapp-popup .country-code {
    background-color: #f0f0f0;
    padding: 10px;
    border: 1px solid #ccc;
    border-right: none;
    border-radius: 5px 0 0 5px;
}

.whatsapp-popup input[type="text"] {
    padding: 10px;
    border: 1px solid #ccc;
    border-left: none;
    border-radius: 0 5px 5px 0;
    flex: 1;
}

.whatsapp-popup button[type="submit"] {
    padding: 10px 20px;
    background-color: #00a8ff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.whatsapp-popup button[type="submit"]:hover {
    background-color: #00a8ff;
}

.whatsapp-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 25px;
    cursor: pointer;
    color: #000;
}

.error-message {
    color: red;
    margin-bottom: 20px;
}

.success-message {
    color: #006aff;
    font-size: 16px;
    text-align: center;
}

@media (max-width: 480px) {
    .whatsapp-popup {
        padding: 20px;
    }

    .whatsapp-popup h2 {
        font-size: 18px;
    }

    .whatsapp-popup button[type="submit"] {
        width: 100%;
    }

    .whatsapp-popup .country-code,
    .whatsapp-popup input[type="text"] {
        font-size: 14px;
    }
}