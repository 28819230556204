/* EnterprisePTPAndPtMPUBRCategoryPage.css */

.enterprise-category {
  background-color: black;
}

.navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1030;
}

.container-enterprise {
  /* background-color: transparent !important; */
  background-color: #333;
  padding-top: 80px;
}

.enterprise-category-heading {
  color: white;
  padding-left: 30px;
}

.enterprise-subcategory-heading {
  color: white;
  padding-left: 30px;
  display: flex;
  justify-content: center;
}

.enterprise-category-information {
  background-color: #1a1a1a;
  border-radius: 8px;
  padding: 20px;
  margin: 20px 30px;
  color: white;
  line-height: 1.6;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  text-align: center;
}

.enterprise-category-information:hover {
  transform: scale(1.02);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

.enterprise-subcategory-button {
  padding-bottom: 15px;
}

.slick-slider {
  width: 90%;
  margin: 0 auto;
  z-index: 1;
}

.slick-slide img {
  width: 100%;
  display: block;
}

.slick-dots {
  bottom: -30px;
}

.slick-dots li button:before {
  color: white;
}

.slick-dots li.slick-active button:before {
  color: white !important;
}

.slick-prev:before,
.slick-next:before {
  color: white;
}

.card-main-enterprise {
  box-shadow: 0 2px 4px rgba(230, 217, 217, 0.1);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.card-img-top-enterprise {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.card-body-enterprise {
  padding: 1rem;
}

.card-title-enterprise {
  font-size: 30px;
}

.card-title-enterprise,
.card-text-enterprise {
  color: white;
  margin-bottom: 25px;
}

.btn-primary {
  background-color: #007bff;
  border-color: #007bff;
  border-radius: 5px;
  padding: 5px;
}

.btn-primary:hover {
  box-shadow: 0 4px 8px rgba(27, 134, 216, 0.8);
  transform: scale(1.03);
  background-color: #007bff;
  border-color: #007bff;
}

.subcategory-container-enterprise {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.enterprise-products-row {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 20px;
}

.card-container-enterprise {
  flex: 0 0 calc(33.333% - 30px);
  margin: 10 10px;
  box-sizing: border-box;
  padding: 15px;
}

.enterprise-products-row.two-cards .card-container-enterprise {
  flex: 0 0 calc(45% - 30px);
  max-width: calc(45% - 30px);
}

@media (max-width: 768px) {
  .enterprise-products-row {
    flex-direction: column;
    align-items: center;
  }

  .enterprise-products-row>div {
    width: 100%;
    margin-bottom: 20px;
  }

  .enterprise-products-row>div:last-child {
    margin-bottom: 0;
  }

  .container-enterprise {
    padding: 0 15px;
    padding-top: 100px;
  }

  .slick-slider {
    width: 95%;
  }

  .slick-slide img {
    height: auto;
  }

  .card-img-top-enterprise {
    height: 150px;
  }

  .card-body-enterprise {
    padding: 0.5rem;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.card-main-enterprise {
  animation: fadeIn 1s ease-out;
}

.card-main-enterprise:hover {
  transform: scale(1.03);
  box-shadow: 0 4px 8px rgba(254, 252, 252, 0.2);
}

.enterprise-products-row.two-cards {
  justify-content: space-around;
  padding-left: 15%;
  padding-right: 15%;
}