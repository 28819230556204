.floating-container {
  position: fixed;
  bottom: 20px;
  left: 20px;
  z-index: 1000;
}

.floating-button {
  background-color: #00C9FF;
  /* Adjust color */
  border: none;
  border-radius: 50%;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  animation: ring 1s infinite ease-in-out;
  /* Apply the ringing animation */
}

.floating-button svg {
  fill: white;
  width: 30px;
  height: 30px;
}

.floating-options {
  position: absolute;
  bottom: 70px;
  /* Distance from the floating button */
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 999;
}

.option-button {
  background-color: #00C9FF;
  border: none;
  border-radius: 50%;
  padding: 10px;
  margin: 5px 0;
  cursor: pointer;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
}

.option-button svg {
  fill: white;
  width: 24px;
  height: 24px;
}

@keyframes ring {
  0% {
    transform: rotate(0deg);
  }

  10% {
    transform: rotate(5deg);
  }

  20% {
    transform: rotate(-5deg);
  }

  30% {
    transform: rotate(3deg);
  }

  40% {
    transform: rotate(-3deg);
  }

  50% {
    transform: rotate(2deg);
  }

  60% {
    transform: rotate(-2deg);
  }

  70% {
    transform: rotate(1deg);
  }

  80% {
    transform: rotate(-1deg);
  }

  90% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(0deg);
  }
}