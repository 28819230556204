/* EnterpriseStructuredCablingSubservicePage.css */

.enterprise-servive-des {
    background-color: #1a1a1a;
    border-radius: 8px;
    padding: 20px;
    margin: 20px 30px;
    color: white;
    line-height: 1.6;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    text-align: center;
}

.enterprise-servive-des:hover {
    transform: scale(1.02);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

.enterprise-service-page {
    background-color: black;
}

.enterprise-subservice-header-cable {
    padding-top: 100px;
    width: 100%;
    height: 95vh;
    background: linear-gradient(rgba(0, 0, 0, 0.55), rgba(0, 0, 0, 0.55)),
        url('../../../assets/images/structured-cabling_use.jpg');
    background-size: cover;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.enterprise-subheader-text {
    width: 700px;
    text-align: center;
}

.enterprise-subservice-header-cable .enterprise-subservice-header-heading {
    margin-bottom: 10px;
    font-weight: 500;
    font-size: 45px;
    color: white;
}

#enterprise-subservice-header-subheading {
    font-size: 60px;
    letter-spacing: 0.5px;
}

.enterprise-subservice-header-cable .enterprise-subservice-header-description {
    margin-bottom: 20px;
    color: white;
    font-size: 18px;
}

.enterprise-subservice-header-cable .enterprise-subservice-header-button {
    border: 2px solid white;
    color: white;
    padding: 10px;
    font-size: 18px;
    width: 160px;
    border-radius: 500px;
    cursor: pointer;
    background-color: transparent;
    transition: all 0.2s ease-in-out;
}

.enterprise-subservice-header-cable .enterprise-subservice-header-button:hover,
.enterprise-subservice-header-button:active {
    background-color: white;
    color: black;
}

.Downloadpdf {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 12px 24px;
    font-size: 16px;
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
}

.Downloadpdf:hover {
    background-color: #0056b3;
    transform: translateY(-2px);
}

.Downloadpdf:active {
    background-color: #004085;
    transform: translateY(0);
}

.Downloadpdf:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5);
}

/* Media queries for responsiveness */
@media screen and (max-width: 676px) {
    .enterprise-subheader-text {
        width: 95%;
        text-align: center;
    }
}

@media screen and (max-width: 601px) {
    #enterprise-subservice-header-subheading {
        font-size: 45px;
    }

    .enterprise-subservice-header-cable .enterprise-subservice-header-heading {
        font-size: 30px;
    }

    .enterprise-subservice-header-cable .enterprise-subservice-header-descriptionp {
        font-size: 15px;
        width: 85%;
        display: block;
        margin: auto;
        margin-bottom: 20px;
    }

    .enterprise-subservice-header-cable .enterprise-subservice-header-button {
        font-size: 16px;
        width: 140px;
    }
}

@media screen and (max-width: 460px) {
    #enterprise-subservice-header-subheading {
        font-size: 35px;
    }

    .enterprise-subservice-header-cable .enterprise-subservice-header-heading {
        font-size: 28px;
    }
}


/* middle css */

#headlineId {
    background-color: black;
}

.enterprise-headline-container {
    text-align: center;
    padding: 20px;
    background-color: #f4f4f4;
    /* Background color */
    border: 1px solid #ccc;
    /* Border color */
    border-radius: 8px;
    /* Rounded corners */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    /* Shadow effect */
}

.enterprise-headline-title {
    font-size: 2.5rem;
    margin-bottom: 10px;
    color: white;
}

.enterprise-headline-description {
    font-size: 1.2rem;
    color: #666;
}

@media (max-width: 768px) {
    .enterprise-headline-title {
        font-size: 2rem;
    }

    .enterprise-headline-description {
        font-size: 1rem;
    }
}

/* end css */

.enterprise-subservice-card-div-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 100px 50px;
    padding: 100px 50px;
}

.enterprise-subservice-card-div-container .enterprise-subservice-card-div {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 350px;
    max-width: 100%;
    height: 300px;
    background: #FFF;
    border-radius: 20px;
    transition: 0.5s;
    box-shadow: 0 35px 80px rgba(0, 0, 0, 0.15);
}

.enterprise-subservice-card-div-container .enterprise-subservice-card-div:hover {
    height: 400px;
}

.enterprise-subservice-card-div-container .enterprise-subservice-card-div .enterprise-subservice-card-img-box {
    position: absolute;
    top: 20px;
    width: 300px;
    height: 220px;
    /* background: #333; */
    border-radius: 12px;
    overflow: hidden;
    transition: 0.5s;
}

.enterprise-subservice-card-div-container .enterprise-subservice-card-div:hover .enterprise-subservice-card-img-box {
    top: -100px;
    scale: 0.75;
    box-shadow: 0 15px 45px rgba(0, 0, 0, 0.2);
}

.enterprise-subservice-card-div-container .enterprise-subservice-card-div .enterprise-subservice-card-img-box img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.enterprise-subservice-card-div-container .enterprise-subservice-card-div .enterprise-subservice-card-div-content {
    position: absolute;
    top: 252px;
    width: 100%;
    height: 35px;
    padding: 0 30px;
    text-align: center;
    overflow: hidden;
    transition: 0.5s;
}

.enterprise-subservice-card-div-container .enterprise-subservice-card-div:hover .enterprise-subservice-card-div-content {
    top: 130px;
    height: 250px;
}

.enterprise-subservice-card-div-container .enterprise-subservice-card-div .enterprise-subservice-card-div-content .enterprise-subservice-card-div-heading {
    font-size: 1.5rem;
    font-weight: 700;
    color: var(--clr);
}

.enterprise-subservice-card-div-container .enterprise-subservice-card-div .enterprise-subservice-card-div-content .enterprise-subservice-card-div-description {
    color: #333;
}

.enterprise-subservice-card-div-container .enterprise-subservice-card-div .enterprise-subservice-card-div-content a {
    position: relative;
    top: 15px;
    display: inline-block;
    padding: 12px 25px;
    text-decoration: none;
    background: var(--clr);
    color: #FFF;
    font-weight: 500;
}

.enterprise-subservice-card-div-container .enterprise-subservice-card-div .enterprise-subservice-card-div-content a:hover {
    opacity: 0.8;
}

@media (max-width: 480px) {
    .enterprise-subservice-card-div-container .enterprise-subservice-card-div {
        width: 230px;
        border-radius: 15px;
    }

    .enterprise-subservice-card-div-container .enterprise-subservice-card-div .enterprise-subservice-card-img-box img {
        /* width: 185px; */
        border-radius: 10px;
    }

    .enterprise-subservice-card-div-container .enterprise-subservice-card-div .enterprise-subservice-card-div-content .enterprise-subservice-card-div-description {
        font-size: 0.8rem;
    }

    .enterprise-subservice-card-div-container .enterprise-subservice-card-div .enterprise-subservice-card-div-content a {
        font-size: 0.9rem;
    }
}