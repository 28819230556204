.call-button {
    display: inline-block;
    background-color: #873e23; /* Beautiful brown color */
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    padding: 12px 20px;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s, background-color 0.3s;
  }
  
  .call-button:hover {
    background-color: #6a2f1c; /* Darker shade of brown */
    transform: scale(1.05);
  }
  
  .call-button:active {
    transform: scale(0.95);
  }
  
  /* Fixed position at the bottom of the screen */
  .fixed-bottom {
    position: fixed;
    bottom: 20px;
    right: 20px; /* Place it on the right */
    z-index: 1000; /* Ensure it stays above other elements */
  }
  