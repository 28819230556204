.enquiry-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    padding: 20px;
}

.enquiry-popup {
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    width: 500px;
    max-width: 100%;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    animation: fadeIn 0.5s forwards;
}

.enquiry-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
}

.enquiry-title {
    font-size: 1.5rem;
    font-weight: bold;
    color: #000;
}

.close-button {
    background: none;
    border: none;
    font-size: 2rem;
    color: #333;
    cursor: pointer;
    outline: none;
}

.enquiry-body {
    padding: 10px 0;
}

.message-form label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #333;
}

.message-form input[type="text"],
.message-form input[type="email"],
.message-form textarea {
    width: 100%;
    margin-bottom: 15px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    outline: none;
    font-size: 1rem;
}

.message-form textarea {
    resize: vertical;
    height: 100px;
}

.submit-button {
    width: 100%;
    padding: 12px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    outline: none;
    transition: background-color 0.3s ease;
}

.submit-button:hover {
    background-color: #0056b3;
}

@media (max-width: 600px) {
    .enquiry-popup {
        width: 90%;
        padding: 15px;
    }

    .enquiry-title {
        font-size: 1.2rem;
    }

    .submit-button {
        padding: 10px;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}