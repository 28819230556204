/* ContactUsBot.css */

.contact-bot-container {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 999;
}

.chat-icon {
    background-color: #007bff;
    color: #fff;
    padding: 10px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
}

.chatbox {
    display: none;
    position: absolute;
    bottom: 50px;
    right: 0;
    width: 300px;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    animation: slideIn 0.5s forwards;
}

@keyframes slideIn {
    from {
        transform: translateY(100%);
    }

    to {
        transform: translateY(0);
    }
}

.chatbox.open {
    display: block;
}

.chat-header {
    background-color: #007bff;
    color: #fff;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.chat-title {
    font-weight: bold;
}

.close-button.contactBot {
    border: none;
    color: #ffffff;
    cursor: pointer;
    outline: none;
    font-size: 1rem !important;
}

.chat-messages {
    overflow-y: auto;
    padding: 10px;
}

.message {
    max-width: 70%;
    margin: 5px;
    padding: 8px;
    border-radius: 10px;
}

.message.bot {
    background-color: #f0f0f0;
    color: #333;
}

.message-form {
    padding: 10px;
}

.message-form.open {
    display: flex;
    flex-direction: column;
}

.message-form input[type="text"],
.message-form input[type="email"],
.message-form input[type="tel"],
.message-form textarea {
    margin-bottom: 10px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
    outline: none;
    animation: fadeIn 0.5s forwards;
    font-size: 1rem;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.message-form .SubmitButton {
    padding: 8px 16px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    outline: none;
    animation: fadeIn 0.5s forwards;
}

.message-form .SubmitButton:hover {
    background-color: #0056b3;
}