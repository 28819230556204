.products-linked-container {
    margin: 20px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    background-color: #e0e0e0;
}

.products-linked-container h2 {
    margin-bottom: 20px;
    font-size: 1.5em;
    color: #333;
    text-align: center;
}

.products-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.product-card {
    flex: 1 1 calc(33% - 20px);
    box-sizing: border-box;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 10px;
    background-color: #d0d0d0;
    text-align: center;
    text-decoration: none;
    color: inherit;
    transition: transform 0.3s, box-shadow 0.3s;
}

.product-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.product-image {
    max-width: 100%;
    height: auto;
    border-radius: 5px;
    margin-bottom: 10px;
}

.product-name {
    font-size: 1.1em;
    color: #555;
}

.arrow-popup {
    position: fixed;
    top: 480px;
    right: -300px;
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
    z-index: 1000;
    font-size: 1em;
    transition: transform 0.3s, right 0.5s;
}

.arrow-popup.pop-in {
    right: 10px;
}

.arrow-popup:hover {
    background-color: #0056b3;
}

@media (max-width: 768px) {
    .arrow-popup {
        padding: 8px 16px;
        font-size: 0.9em;
    }
}

/* 
.arrow-popup {
    position: fixed;
    top: 140px;
    right: -200px;
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
    z-index: 1000;
    font-size: 1em;
    transition: transform 0.3s, right 0.5s;
}

.arrow-popup::before {
    content: '';
    position: absolute;
    top: 50%;
    left: -10px;
    transform: translateY(-50%);
    border-width: 10px 0 10px 10px;
    border-style: solid;
    border-color: transparent transparent transparent #007bff;
}

.arrow-popup.pop-in {
    right: 10px;
}

.arrow-popup:hover {
    background-color: #0056b3;
} */