@import url("https://fonts.googleapis.com/css2?family=PT+Sans:wght@700&family=Muli&display=swap");

@keyframes bounceIn {
    0% {
        transform: scale(0.8);
        opacity: 0;
    }

    60% {
        transform: scale(1.1);
        opacity: 1;
    }

    100% {
        transform: scale(1);
    }
}

.logo1,
.footer {
    display: flex;
    width: 100%;
    position: absolute;
}

.logo1 {
    top: 2%;
    z-index: 10;
    justify-content: center;
}

.logo1 .logo-container {
    padding: 0.2rem 1rem;
    position: relative;
    overflow: hidden;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
    background-color: #00000061;
    background-color: #000000a3;
}

.logo1 img {
    height: 100px;
}

@media (max-width: 390px) {
    .logo1 img {
        height: 35px !important;
    }
}

@media (max-width: 576px) {
    .logo1 img {
        height: 35px !important;
    }
}

@media only screen and (max-width: 768px) {
    .logo1 img {
        height: 42px;
    }
}

.footer {
    color: #fff;
    bottom: 1%;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.footer p {
    font-size: 1rem;
}

@media (max-width: 576px) {
    .footer p {
        font-size: 0.8rem;
    }
}

.landing-social-links {
    padding-left: 0;
    margin: 0;
}

.landing-social-links li {
    list-style-type: none;
    display: inline;
    padding: 0.2rem 0.8rem;
}

.landing-social-links a i {
    color: #fff;
    transition: all ease-in-out 300ms;
}

.landing-social-links a:hover i {
    color: #CC917A;
}

.oblique {
    position: relative;
    overflow: hidden;
}

.main-block-oblique {
    overflow: hidden;
    position: relative;
    z-index: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
}

@media (max-width: 768px) {
    .main-block-oblique {
        flex-direction: column;
    }

    .skew-block-repeat {
        width: 100%;
        margin: 0;
    }

    .oblique-inner {
        margin-bottom: 20px;
    }
}

.skew-block {
    margin-left: -10vh;
    margin-right: -10vh;
}

.skew-block-repeat {
    will-change: transform;
    transition: all 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
    opacity: 1;
    min-height: 100vh;
    float: left;
    position: relative;
    z-index: 0;
    flex: 1;
    backface-visibility: hidden;
    margin: 0 -1px;
}

@media (max-width: 768px) {
    .skew-block-repeat {
        min-height: 50vh;
    }
}

.skew-block-repeat:hover {
    flex: 1.5;
    opacity: 0.8;
}

.skew-block-repeat .image-img {
    position: absolute;
    width: 100%;
    object-fit: cover;
    height: 100%;
    backface-visibility: hidden;
    transition: 0.2s ease-out;
    max-width: 100%;
    max-height: 100%;
    vertical-align: middle;
    border: 0;
    filter: blur(2px);
    /* Adjust the blur intensity as needed */
}


.skew-block-repeat a .oblique-inner {
    transform: skewX(-10deg);
    margin-left: 0;
    margin-right: 0;
    transition: box-shadow 0.2s ease-out;
    position: relative;
    backface-visibility: hidden;
    overflow: hidden;
    height: 100%;
    position: absolute;
    width: 100%;
}

.skew-block-repeat a .oblique-inner .image-wrapper {
    margin-left: -10vh;
    margin-right: -10vh;
    transform: skewX(10deg);
    position: relative;
    backface-visibility: hidden;
    height: 100%;
}

.skew-block-repeat a .oblique-inner .image-wrapper .main-image {
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
}

.skew-block-repeat a .oblique-inner .image-wrapper .main-image .image-img {
    position: absolute;
    width: 100%;
    object-fit: cover;
    height: 100%;
    backface-visibility: hidden;
    transition: 0.2s ease-out;
    max-width: 100%;
    max-height: 100%;
    vertical-align: middle;
    border: 0;
}

.skew-block-repeat:hover a .oblique-caption h2 {
    animation: bounceIn 1200ms;
}

.skew-block-repeat a .oblique-caption {
    position: absolute;
    bottom: 90px;
    color: #fff;
    z-index: 1;
    width: 100%;
    bottom: auto;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
}

.skew-block-repeat a .oblique-caption.caption-top {
    @media (max-width: 768px) {
        top: 60%;
    }
}

.skew-block-repeat a .oblique-caption.caption-bottom {
    @media (max-width: 768px) {
        top: 35%;
    }
}

.skew-block-repeat a .oblique-caption h2 {
    font-size: 3rem;
    color: #fff;
    margin: 0;
    padding: 0;
    letter-spacing: 0.1rem;
}

@media (max-width: 992px) {
    .skew-block-repeat a .oblique-caption h2 {
        font-size: 1.9rem;
    }
}

.skew-block-repeat a .oblique-caption button {
    border-radius: 25px;
    position: relative;
    overflow: hidden;
    background: transparent;
    color: #fff;
    border: 2px solid transparent;
    padding: 0.8rem 4rem;
    letter-spacing: 0.1rem;
    margin-top: 1rem;
    /* opacity: 0.5; */
    cursor: pointer;
    transition: all 800ms;
    font-size: 20px;
}

@media (max-width: 992px) {
    .skew-block-repeat a .oblique-caption button {
        font-size: 15px;
        padding: 0.8rem 3rem;
    }
}



.skew-block-repeat a .oblique-caption button::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(45deg, #00ffff, #0080ff, #00ffff);
    transition: all 0.4s ease;
    z-index: -1;
    animation: neon 1.5s infinite alternate;
}

.skew-block-repeat a .oblique-caption button:hover::before {
    left: -100%;
}

.skew-block-repeat a .oblique-caption button:hover {
    color: #fff;
    border-color: #00ffff;
}

.skew-block-repeat a .oblique-caption button:hover::before {
    background: linear-gradient(45deg, #00ffff, #0080ff, #00ffff);
}

.skew-block-repeat a .oblique-caption h2 {
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.blur-background {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    background: url('./assets/images/logo_with_name_bgremove.png') center/cover fixed;
    filter: blur(300px);
    z-index: -1;
}

.logo-container {
    position: relative;
    z-index: 1;
}

.logo-container::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    z-index: -1;
}

@keyframes neon {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(1.1);
    }
}


/* Example of Media Queries */
@media only screen and (max-width: 768px) {}


.dividing-logo-text {
    font-weight: 600;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    padding: 10px 0;
    text-align: center;
    z-index: 100;
}