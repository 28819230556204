/* Reviews.css */

.prefix-testimonials {
    background-color: #343434;
    margin: 0 auto;
}

@media (max-width: 1440px) {
    .prefix-main-wrapper {
        width: 100vw;
    }
}

.prefix-back-text {
    position: relative;
    z-index: -2000;
}


.prefix-reviews {
    overflow: hidden;
}

.prefix-reviews-title {
    text-align: center;
}

.prefix-reviews-title p {
    font-size: 5rem;
    color: white;
    padding: 20px;
}

.prefix-reviews-card {
    display: grid;
    grid-template-columns: 1fr 480px;
    width: 781px;
    color: #c4bdbd;
}

@media (max-width: 425px) {
    .prefix-reviews-card {
        grid-template-columns: 1fr;
        width: fit-content;
    }
}

.prefix-card-img {
    width: fit-content;
    height: fit-content;
}

.prefix-card-text {
    background-color: #1d1d1d;
    padding: 2rem 4rem;
    height: 359px;
    overflow: hidden;
}

@media (max-width: 425px) {
    .prefix-card-text {
        padding: 1rem 2rem;
        width: 70vw;
        height: fit-content;
    }
}

.prefix-card-title p {
    font-weight: 800;
    font-size: 2rem;
    line-height: 105.52%;
}

.prefix-card-para {
    margin-top: 1rem;
    margin-bottom: 0.8rem;
}

.prefix-card-author {
    display: flex;
    align-items: center;
    gap: 0.3rem;
}


.prefix-reviews-row {
    display: flex;
    gap: 2rem;
}

@media (max-width: 425px) {
    .prefix-card-title p {
        font-weight: 700;
        font-size: 1rem;
    }

    .prefix-card-para p {
        font-size: 0.7rem;
    }

    .prefix-card-author p {
        font-size: 0.7rem;
    }

    .prefix-reviews-row {
        flex-direction: column;
        margin: 0 auto;
        width: fit-content;
    }

    .prefix-reviews img {
        width: 70vw;
    }

    /* Change images for mobile view */
    .tata-image {
        content: url('../assets/images/TataCommunicationsMobile.jpg');
    }

    .karkinos-image {
        content: url('../assets/images/KarkinosMobile.jpg');
    }

    .axxela-image {
        content: url('../assets/images/AxxelaMobile.jpg');
    }
}

.prefix-row-first,
.prefix-row-third {
    animation: move-first 20s linear infinite;
    margin-bottom: 2rem;
}

.prefix-row-second {
    transform: translateX(-1000px);
    animation: move-second 20s linear infinite;
    margin-bottom: 2rem;
}

@media (max-width: 425px) {

    .prefix-row-second,
    .prefix-row-third {
        display: none;
    }

    .prefix-row-first {
        animation: none;
    }
}

@keyframes move-first {
    50% {
        transform: translateX(-1000px);
    }

    100% {
        transform: translateX(0);
    }
}

@keyframes move-second {
    50% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-1000px);
    }
}