/* EnterpriseCaseStudyCard.css */

.case-study-card .front .neon-border {
    border-radius: 10px;
    overflow: hidden;
}

.case-study-card .front img {
    width: 100px;
    height: 100px;
    padding: 8px;
}

.case-study-card .back .card-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.case-study-card .back p {
    text-align: center;
}

.case-study-card .back a {
    margin-top: 20px;
}

.case-study-card .front .card-title {
    font-weight: bold;
    color: white;
}

.case-study-page {
    text-align: center;
    background-color: #343333;
    margin-top: 150px;
}

.headline {
    padding: 50px;
}

.headline h1 {
    font-size: 2rem;
    margin-bottom: 10px;
}

.headline p {
    font-size: 1rem;
    color: #fff;
}

.case-study-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    opacity: 0;
}

.case-study-card:hover {
    border: 2px solid #00C9FF;
    transform: scale(1.03);
    /* box-shadow: 0 2px 4px #00C9FF; */
}

.case-study-card {
    border-radius: 10px;
    width: calc(50% - 100px);
    max-width: 500px;
    margin: 0 10px 20px;
    padding: 20px;
    background-color: #333;
    color: #fff;
    transition: transform 0.9s cubic-bezier(0.4, 0, 0.2, 1);
    position: relative;
    transform-style: preserve-3d;
    perspective: 1000px;
}

.case-study-card.front,
.case-study-card.back {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
}

.case-study-card .back {
    display: none;
    transform: rotateY(180deg);
}

.case-study-card.back .card-content {
    transform: rotateY(180deg);
}

.case-study-card.front {
    z-index: 2;
    transform: rotateY(0deg);
}

.case-study-card.back {
    display: none;
    transform: rotateY(180deg);
}

.case-study-card img {
    width: 100px;
    height: 100px;
    margin-bottom: 10px;
}

.case-study-card p {
    font-size: 1rem;
}

.case-study-card a {
    color: #00a8ff;
    text-decoration: none;
    font-weight: bold;
}

.case-study-card .front,
.case-study-card .back {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.329);
}

@media (max-width: 768px) {
    .case-study-card {
        width: calc(100% - 20px);
    }

    #CaseStudyPageInnerDiv {
        padding-top: 300px !important;

    }
}

.loaded .case-study-cards {
    opacity: 1;
    margin-top: 20px;
    gap: 60px;
}

.right-slide-in .case-study-card {
    animation: slideInRight 0.5s forwards;
}

.left-slide-in .case-study-card {
    animation: slideInLeft 0.5s forwards;
}

@keyframes slideInRight {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(0);
    }
}

@keyframes slideInLeft {
    0% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(0);
    }
}

/* headline css */

#CaseStudyDv {
    text-align: center;
    padding: 40px;
    background-color: #333333;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
    color: #ffffff;
    border-color: #1a1a1a;
    color: #1a1a1a;
}

#CaseStudyDv h1 {
    font-size: 44px;
    font-weight: bold;
    margin-bottom: 20px;
    text-transform: uppercase;
    letter-spacing: 2px;
    animation: fadeInUp 1s ease-out;
    color: #00a8ff;
}

#CaseStudyDv p {
    font-size: 20px;
    margin-top: 0;
    animation: fadeInUp 1s ease-out 0.3s;
    color: #7f8c8d;
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes borderPulse {
    0% {
        border-color: #00a8ff;
    }

    50% {
        border-color: #7f8c8d;
    }

    100% {
        border-color: #00a8ff;
    }
}