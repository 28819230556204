.small-header {
    position: fixed;
    top: 0;
    left: 0;
    height: 40px;
    width: 100%;
    background-color: #007bff;
    color: #fff;
    text-align: center;
    padding: 10px 0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    border: 2px solid transparent;
    z-index: 2;
}

.custom-header-unique {
    background-color: #fff;
    color: #333;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    transition: background-color 0.3s ease;
}

.logo-unique {
    display: flex;
    align-items: center;
    font-size: 1.2rem;
    font-weight: bold;
}

.logo-unique img {
    width: 80px;
    margin-right: 5px;
}

.site-banner {
    font-size: 1.1rem;
    font-weight: bold;
}

.links-unique {
    list-style-type: none;
    display: flex;
}

.links-unique li {
    font-size: 1rem;
}

.icon-burger-unique {
    display: none;
    cursor: pointer;
}

.line-unique {
    width: 25px;
    height: 3px;
    background-color: #333;
    margin: 4px 0;
}

.job-title {
    font-size: 2rem;
    margin-bottom: 20px;
    color: #333;
    text-align: center;
    transition: opacity 0.5s ease-in-out;
}

.job-title.fade-in {
    opacity: 1;
}

.JobBoardDiv {
    padding-top: 140px;
}

.job-details-div {
    display: flex;
    gap: 100px;
}

.apply-div {
    display: flex;
    gap: 22px;
    flex-direction: column;
    padding-top: 10px;
    align-items: center;
}

.job-board-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #2e2b2b;
}

.job-card-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 30px;
    justify-content: center;
    width: 100%;
    max-width: 1200px;
    margin-top: 20px;
}

.job-card {
    background-color: #1f1f1f;
    color: #ffffff;
    border-radius: 8px;
    padding: 15px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;
}

.job-card:hover {
    transform: translateY(-5px);
}

.job-card h2 {
    font-size: 1.3rem;
    margin-bottom: 10px;
    height: 60px;
}

@media (max-width: 768px) {
    .job-card h2 {
        margin-bottom: 20px;
    }

    .apply-div {
        padding-top: 70px;
    }
}

.job-card p {
    font-size: 1rem;
    margin-bottom: 15px;
    height: 150px;
}

.job-details {
    display: flex;
    font-size: 0.9rem;
    color: #b3b3b3;
    width: 100%;
    gap: 5px;
}

.apply-button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
    width: 150px;
}

.apply-button:hover {
    background-color: #0056b3;
}

@media (max-width: 768px) {
    .job-card-grid {
        grid-template-columns: 1fr;
        justify-items: center;
    }

    .job-card {
        width: 90%;
    }

    .icon-burger-unique {
        display: block;
    }

    .links-unique li {
        font-size: 1.2rem;
    }
}